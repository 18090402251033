import instagramIcon from '../assets/images/footer/instagramIcon.svg';
import linkedInIcon from '../assets/images/footer/linkedInIcon.svg';
import xIcon from '../assets/images/footer/xIcon.svg';
import facebookIcon from '../assets/images/footer/facebookIcon.svg';
import youtubeIcon from '../assets/images/footer/youtubeIcon.svg';

import instagramIconAbout from '../assets/images/about/instagramIcon.svg';
import linkedInIconAbout from '../assets/images/about/linkedinIcon.svg';
import xIconAbout from '../assets/images/about/xIcon.svg';
import facebookIconAbout from '../assets/images/about/facebookIcon.svg';
import youtubeIconAbout from '../assets/images/about/youtubeIcon.svg';

export default [
    {
        name: 'Instagram',
        url: "https://www.instagram.com/outplayed.in?utm_source=qr&igsh=MTUyY20yZmtxcjU5ZA==",
        footerIcon: instagramIcon,
        aboutSectionIcon: instagramIconAbout
    },
    {
        name: 'LinkedIn',
        url: "https://www.linkedin.com/company/outplayed-in/",
        footerIcon: linkedInIcon,
        aboutSectionIcon: linkedInIconAbout
    },
    {
        name: 'X',
        url: "https://x.com/Outplayed_in?t=bGEVTcRLXS4-u_VVqvfq0w&s=08",
        footerIcon: xIcon,
        aboutSectionIcon: xIconAbout
    },
    // {
    //     name: 'Facebook',
    //     url: "https://www.facebook.com/profile.php?id=61563612749800&mibextid=ZbWKwL",
    //     footerIcon: facebookIcon,
    //     aboutSectionIcon: facebookIconAbout
    // },
    {
        name: 'YouTube',
        url: "https://www.youtube.com/@Outplayed_in",
        footerIcon: youtubeIcon,
        aboutSectionIcon: youtubeIconAbout
    }
]