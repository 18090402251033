import EventRegisterButton from '../../Buttons/EventRegisterButton';
import EventCardFirstBoxItem from './EventCardFirstBoxItem';
import trophyIcon from '../../../assets/images/events/trophyIcon.svg';
import calendarIcon from '../../../assets/images/events/calendarIcon.svg';
import clockIcon from '../../../assets/images/events/clockIcon.svg';
import locationIcon from '../../../assets/images/events/locationIcon.svg';

export default function EventsCard({ prize, date, time, venue, title, game, url }) {
    return (
        <div className="events-card">
            <div className="events-card__first-box">
                <EventCardFirstBoxItem icon={trophyIcon} title="PRIZE POOL" value={prize} />
                <EventCardFirstBoxItem icon={calendarIcon} title="DATE" value={date} />
                <EventCardFirstBoxItem icon={clockIcon} title="TIME" value={time} />
                <EventCardFirstBoxItem icon={locationIcon} title="VENUE" value={venue} />
            </div>
            <div className="events-card__second-box">
                <h1>{title}</h1>
                <p>{game}</p>
                <EventRegisterButton text="Register Now" url={url} />
            </div>
        </div>
    )
}