import { useEffect, useState } from 'react';
import games from '../../../constants/games';
import GamesCard from './CamesCard';

export default function GamesContainer() {
    const [gamesCollection, setGamesCollection] = useState([]);

    useEffect(() => {
        setGamesCollection((prevGamesCollection) => {
            return games.map(game => {
                return <GamesCard image={game.image} title={game.title} />
            })
        })
    }, [])

    return (
        <div className="games-container">
            {gamesCollection}
        </div>
    )
}