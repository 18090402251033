import WarningIcon from '../../assets/images/errorPopup/warningIcon.svg';
import CloseButton from '../../assets/images/errorPopup/closeButton.svg'

export default function FormPopup({ icon, message1, message2, handleClose }) {
    return (
        <div className='form-popup'>
            <div className='form-popup__container'>
                <div><img src={icon} alt="warning icon" style={{ width: "2rem" }}/></div>
                <div>
                    <span style={{ fontWeight: "600" }}>{message1}</span>
                    <span>{message2}</span>
                </div>
                <div onClick={() => { handleClose(false) }}><img src={CloseButton} alt="close icon"/></div>
            </div>
        </div>
    )
}