import valorant from '../assets/images/games/valorant.png';
import bgmi from '../assets/images/games/bgmi.png';
import counterStrike from '../assets/images/games/counterStrike.png';
import freeFire from '../assets/images/games/freeFire.png';
import mlbb from '../assets/images/games/mlbb.png';
export default [
    {
        image: valorant,
        title: 'Valorant'
    },
    {
        image: bgmi,
        title: 'BGMI'
    },
    {
        image: counterStrike,
        title: 'Counter Strike'
    },
    {
        image: freeFire,
        title: 'Free Fire'
    },
    {
        image: mlbb,
        title: 'Moba Legends 5v5'
    }
]