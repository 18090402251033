import roronoa from '../assets/images/wordsFromCrew/roronoa.png';
import spidey from '../assets/images/wordsFromCrew/spidey.png';
import wonky from '../assets/images/wordsFromCrew/wonky.png';
import clueless from '../assets/images/wordsFromCrew/clueless.png';
import crankshaft from '../assets/images/wordsFromCrew/crankshaft.png';

export default [
    {
        name: 'Roronoa',
        role: 'CEO',
        words: 'I really Don’t Like Mondays',
        image: roronoa
    },
    {
        name: 'Crankshaft',
        role: 'CTO',
        words: 'My laptop sucks!',
        image: crankshaft
    },
    {
        name: 'ClueLess',
        role: 'Head of Design',
        words: "Don't choose this life",
        image: clueless
    },
    {
        name: 'Spidey1912',
        role: 'Frontend Architect',
        words: "I don't like designers",
        image: spidey
    },
    {
        name: 'Wonky',
        role: 'DBA',
        words: 'SELECT * FROM sleep',
        image: wonky
    }
]