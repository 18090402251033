import '../../../assets/styles/home/Features.css';
import FeaturesContainer from './FeaturesContainer';

export default function Features() {
    return (
        <section className="features-section" id="features-section">
            <div className="glow zeroth"></div>
            <div className="glow first"></div>
            <div className='features-section-header'>What Do you Get!</div>
            <p>Outplayed goes beyond a standard tournament—it’s an immersive esports experience filled with high-stakes gameplay, team battles, and unforgettable moments.</p>
            <FeaturesContainer />
        </section>
    )
}