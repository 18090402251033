import '../assets/styles/error/Error.css';
import GoToHomeButton from '../components/Buttons/GoToHomeButton';
import OverlayErrorPage from '../components/Overlay/OverlayErrorPage';

export default function Error() {
    return (
        <section className="error-page">
            <div>
                <h1>404: Well Done Zoro</h1>
                <p>Don’t worry, you can still be world’s strongest eSports player.</p>
                <GoToHomeButton text="Go to home page" />
            </div>
            <OverlayErrorPage />
        </section>
    )
}