import React, { Children, useEffect, useState } from "react";
import "../../assets/styles/components/carousel/Carousel.css";
import arrow from "../../../src/assets/images/wordsFromCrew/arrow.png";

export default function Carousel({ crewList }) {
  const middle = Math.floor(crewList.length / 2);

  const [width, setWidth] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [middleElementIndex, setMiddleElementIndex] = useState(middle);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) nextItem();
    if (isRightSwipe) prevItem();
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % crewList.length);
      setMiddleElementIndex((prevIndex) => (prevIndex + 1) % crewList.length);
    }, 6000); // Adjust the interval as needed (2 seconds here)

    return () => clearInterval(intervalId);
  }, []);

  const nextItem = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % crewList.length);
    setMiddleElementIndex((prevIndex) => (prevIndex + 1) % crewList.length);
  };

  const prevItem = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + crewList.length) % crewList.length
    );
    setMiddleElementIndex(
      (prevIndex) => (prevIndex - 1 + crewList.length) % crewList.length
    );
  };

  return (
    <div className="carousel-container">
      <div className="slider">
        <div
          onClick={() => prevItem()}
          className="carousel__left-arrow"
          style={{
            position: "absolute",
            zIndex: 200,
            top: "50%",
            width: "fit-content",
            height: "fit-content",
            cursor: "pointer",
          }}
        >
          <img
            src={arrow}
            alt="Left Arrow"
            style={{ transform: "scaleX(-1)" }}
          ></img>
        </div>
        <div
          onClick={() => nextItem()}
          className="carousel__right-arrow"
          style={{
            position: "absolute",
            zIndex: 200,
            top: "50%",
            width: "fit-content",
            height: "fit-content",
            cursor: "pointer",
          }}
        >
          <img src={arrow} alt="Right Arrow"></img>
        </div>
        {crewList.map((item, index) => {
          // Calculate the correct position with modular arithmetic
          const position =
            ((index - currentIndex + crewList.length) % crewList.length) -
            middle;
          const zIndex = crewList.length - Math.abs(position);

          return (
            <>
              <div
                className="carousel_child_container"
                style={{
                  width: width,
                }}
              ></div>
              <div
                className="carousel__item"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                key={item.name}
                style={{
                  outline: "none",
                  border: "none",
                  transform: `translateX(${
                    position * (width / crewList.length)
                  }px) scale(${100 - Math.abs(position) * 10}%)`,
                  backgroundImage: `linear-gradient(to top, rgba(2, 6, 26, 1), rgba(0,0,0,0)), url(${item.image})`,
                  backgroundSize: "cover",
                  filter:
                    index === middleElementIndex
                      ? "opacity(1)"
                      : "blur(2px) brightness(0.4)",
                  backgroundColor:
                    index === middleElementIndex
                      ? "purple"
                      : `rgba(0, 0, 0, 1)`,
                  zIndex: zIndex,
                  borderRadius: "20px",
                  aspectRatio: 1,
                  display: "flex",
                  position: "absolute",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  boxShadow:
                    index === middleElementIndex
                      ? "0 30px 60px 0px rgba(89, 117, 195, 0.3)"
                      : "none",
                  paddingBottom: "30px",
                  transition: "all 0.5s ease", // Smooth transition effect
                }}
              >
                <div
                  className="carousel__words"
                  style={{
                    fontSize: "1.5rem",
                    fontFamily: "Montserrat",
                    fontStyle: "italic",
                    fontWeight: 600,
                    display: index === middleElementIndex ? "block" : "none",
                  }}
                >
                  "{item.words}"
                </div>
                <div
                  className="carousel__name"
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Krona One",
                    marginTop: "20px",
                    display: index === middleElementIndex ? "block" : "none",
                  }}
                >
                  {item.name}
                </div>
                <div
                  className="carousel__role"
                  style={{
                    fontSize: "1.2rem",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    color: "#A0A0A0",
                    display: index === middleElementIndex ? "block" : "none",
                  }}
                >
                  {item.role}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
