export default [
    {
        prize: '10,000',
        date: '02/11/2024 - 03/11/2024',
        time: '10 AM - 6 PM',
        venue: 'MSSC building, Assam Engineering College, Jalukbari - 13',
        title: 'Gaming Vortex',
        game: 'Clash of Legends',
        url: ''
    },
    // {
    //     prize: '10,000',
    //     date: '02/11/2024 - 03/11/2024',
    //     time: '10 AM - 6 PM',
    //     venue: 'MSSC building, Assam Engineering COllege, Jalukbari - 13',
    //     title: 'Gaming Vortex',
    //     game: 'Clash of Legends'
    // }
]