import '../../assets/styles/components/buttons/GoToHomeButton.css';
import { Link } from "react-router-dom";

export default function GoToHomeButton({ text }) {

    return (
        <a href="http://outplayed.in/" className="go-to-home-button">
            {text}
        </a>
    )
}