import FeaturesCard from './FeaturesCard';
import features from '../../../constants/features';
import { useEffect, useState } from 'react';

export default function FeaturesContainer() {
    const [featuresCollection, setFeaturesCollection] = useState();

    useEffect(() => {
        setFeaturesCollection((prevFeaturesCollection) => {
            return features.map((feature) => {
                return <FeaturesCard title={feature.title} moto={feature.moto} cardType={feature.cardType} />
            })
        })
    }, [])

    return (
        <div className="features-container">
            {featuresCollection}
        </div>
    )
}