import "../../../assets/styles/home/Games.css";
import GamesContainer from "./GamesContainer";
import GamesForm from "./GamesForm";

export default function Games() {
  return (
    <section className="games-section" id="games-section">
        <div className="glow second"></div>
        <div className="glow third"></div>
        <div className="glow fourth"></div>
        <div className="glow fifth"></div>
        <div className="games-section-header">Games We Offer</div>
        <p>
        Outplayed brings premier esports titles to center stage, pushing players' skills, strategy, and teamwork to their limits for ultimate competitive thrill.
        </p>
        <GamesContainer />
        <GamesForm />
    </section>
  );
}
