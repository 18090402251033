import '../../assets/styles/components/buttons/LandingPageButton.css';

export default function LandingPageButton({ text }) {

    const scrollToRegistrationSection = () => {
        document.getElementById('join-us-section').scrollIntoView({ behavior: "smooth" });
    }

    return (
        <button className="landing-page-button" onClick={scrollToRegistrationSection}>
            {text}
        </button>
    )
}