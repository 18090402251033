import '../../../assets/styles/home/Events.css';
import EventsContainer from './EventsContainer';

export default function Events() {
    return (
        <section className="events-section" id="events-section">
            <div className="events-section-header">Upcoming Events</div>
            <p>Stay tuned for registration details, event schedules, and more. Whether you’re a competitor or a fan, Outplayed promises a thrilling experience for all!</p>
            <EventsContainer />
        </section>
    )
}