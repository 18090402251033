import { Outlet } from "react-router-dom";
import "../../assets/styles/components/navbar/Navbar.css";
import OutplayedIcon from "../../assets/images/icons/op_yellow.png";
import OutplayedIconMobile from "../../assets/images/navbarMobile/outplayedIcon.svg";
import HamburgerIcon from "../../assets/images/navbarMobile/hamburgerIcon.svg";
import { useState } from "react";

export default function Navbar() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <>
      <header>
        <nav className={`navbar_mobile`}  style={ isMenuVisible ? { backgroundColor: "#02061A" } : { } }>
          <a href="/">
            <img src={OutplayedIconMobile} alt="Outplayed icon" />
          </a>
          <button
            className="navbar_mobile__hamburger"
            onClick={toggleMenuVisibility}
          >
            <img src={HamburgerIcon} alt="Hamburger icon" />
          </button>
          {isMenuVisible && (
            <div className="navbar_mobile__menu">
              <a href="#footer_m" onClick={toggleMenuVisibility}>
                Contact Us
              </a>
              <a href="#features-section" onClick={toggleMenuVisibility}>
                Services
              </a>
              <a href="#games-section" onClick={toggleMenuVisibility}>
                Games
              </a>
              <a href="#events-section" onClick={toggleMenuVisibility}>
                Events
              </a>
              <a href="#words-from-crew-section" onClick={toggleMenuVisibility}>
                Crew
              </a>
              <a href="#join-us-section" onClick={toggleMenuVisibility}>
                Beta Registration
              </a>
            </div>
          )}
        </nav>
        <nav className="navbar_desktop">
          <section className="navbar-items-container">
            <div
              style={{
                backgroundImage: `url(${OutplayedIcon})`,
                backgroundSize: "8rem",
                height: "100%",
                backgroundRepeat: "no-repeat"
              }}
            >
            </div>
            <a href="#footer_m">Contact Us</a>
            <a href="#features-section">Services</a>
            <a href="#games-section">Games</a>
            <a href="#events-section">Events</a>
            <a href="#words-from-crew-section">Crew</a>
            <a href="#join-us-section" id="nav-cta">
              Register For Beta
            </a>
          </section>
        </nav>
      </header>
    </>
  );
}
