export default [
    {
        cardType: 'grid-item-type-1-features grid-item-tournaments',
        title: 'Tournaments!',
        moto: 'One Shall Stand, One Shall Fall'
    },
    {
        cardType: 'grid-item-type-1-features grid-item-franchise',
        title: 'Own Franchise Creation',
        moto: 'Okay, Nick Fury, Assemble your team'
    },
    {
        cardType: 'grid-item-type-1-features grid-item-gaming-resume',
        title: 'GET Your Gaming Resume',
        moto: 'Go corporate gaming!'
    },
    {
        cardType: 'grid-item-type-2-features grid-item-career',
        title: 'Career in eSports',
        moto: 'Wealth? Fame? Power?'
    },
    {
        cardType: 'grid-item-type-1-features grid-item-moderator-facility',
        title: 'Moderator Facility',
        moto: 'Great Power? Great Responsibility!'
    },
    {
        cardType: 'grid-item-type-3-features grid-item-epic-events',
        title: 'Epic Events',
        moto: 'Your Lineups can Pay the Bill'
    }
]