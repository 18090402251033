import "../assets/styles/home/Hero.css";
import Hero from "../components/Home/Hero";
import Features from "../components/Home/Features/Features";
import Games from '../components/Home/Games/Games';
import Events from "../components/Home/Events/Events";
import WordsFromCrew from "../components/Home/WordsFromCrew/WordsFromCrew";
import JoinUs from "../components/Home/JoinUs/JoinUs";
import fadeValorant from '../assets/images/fadeValorant.mp4';

export default function Home() {
    return (
        <>
            <Hero />
            <Features />
            <Games />
            <Events />
            <WordsFromCrew />
            <JoinUs />

            {/* <div class="video-background">
                <video autoplay muted loop id="bg-video">
                    <source src={fadeValorant} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div class="content">
                    <h1>Welcome to My Website</h1>
                </div>
            </div> */}

        </>
    );
}
