import { useEffect, useState } from "react";
import axios from "axios";
import { FormContext } from "../../../contexts/JoinUsFormContext";
import "../../../assets/styles/home/JoinUs.css";
import JoinUsForm from "./JoinUsForm";
import mobileAppHomeScreen from "../../../assets/images/joinUs/mobileAppHomeScreen.png";
import FormPopup from "../../Popup/FormPopup";
import WarningIcon from "../../../assets/images/errorPopup/warningIcon.svg";
import CheckedIcon from "../../../assets/images/errorPopup/checkedIcon.png";

const baseUrl = process.env.REACT_APP_BASE_URL;
const registerRoute = process.env.REACT_APP_REGISTER_ROUTE;

export default function JoinUs() {
  const [userInfo, setUserInfo] = useState({
    Name: "",
    Email: "",
    Contact: "",
    Preferred_Platform: "",
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupReaction, setPopupReaction] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevUserInfo) => {
      return {
        ...prevUserInfo,
        [name]: value,
      };
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateContact = (contact) => {
    const re = /^\d{10}$/;
    return re.test(contact);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSfnkqscwEFe_-z9vODZMm86Bv5NdAsZNkk9yJ3QZh8HQW3pSA/viewform",
      "_blank"
    );
  };

  useEffect(() => {
    // Re-enable the button when the user returns
    window.addEventListener("focus", () => {
      setIsButtonDisabled(false);
    });

    return () => window.removeEventListener("focus", () => {});
  }, []);
  return (
    <FormContext.Provider
      value={{
        userInfo,
        setUserInfo,
        handleChange,
        handleSubmit,
        showPopup,
        setShowPopup,
        popupMessage,
        setPopupMessage,
      }}
    >
      <section className="join-us-section" id="join-us-section">
        <span className="join-us-section-title">
          Join Us for our Beta Testing!
        </span>
        <p>
          Be among the first to experience Outplayed by joining our exclusive
          beta testing phase! Pre-register now for a chance to test new
          features, provide feedback, and help us refine the ultimate esports
          platform.
        </p>
        <div>
          <div className="join-us-section__mobile-app">
            <img
              style={{ width: "100%" }}
              src={mobileAppHomeScreen}
              alt="Mobile app home screen picture"
            />
          </div>
          <div>
            <JoinUsForm isButtonDisabled={isButtonDisabled} />
            <p className="join-us-disclaimer">
              <span>DISCLAIMER: </span>Your information will be used exclusively
              to contact you about our beta testing and will not be shared with
              any third parties.
            </p>
          </div>
        </div>
        {showPopup && (
          <FormPopup
            icon={popupReaction === "Oops!" ? WarningIcon : CheckedIcon}
            message1={popupReaction}
            message2={popupMessage}
            handleClose={setShowPopup}
          />
        )}
      </section>
    </FormContext.Provider>
  );
}
