export default function EventCardFirstBoxItem({ icon, title, value }) {
    return (
        <div className='events-card__first-box__item'>
            <div>
                <img src={icon} alt={`${title} icon`} />
            </div>
            <div>
                <span>
                    {title}
                </span>
                <span className="events-card__first-box__item_grey-text">
                    {value}
                </span>
            </div>
        </div>
    )
}