import '../../assets/styles/components/buttons/JoinUsSubmitButton.css';
import { useContext } from 'react';
import { FormContext } from '../../contexts/JoinUsFormContext';

export default function JoinUsSubmitButton({ text, isDisabled=false }) {
    
    const { handleSubmit } = useContext(FormContext);
    
    return (
        <button disabled={isDisabled} style={{filter: isDisabled ? "opacity(0.2)" : "none"}} className="join-us-submit-button" onClick={handleSubmit}>
            {isDisabled ? "Please Wait..." : text}
        </button>
    )
}