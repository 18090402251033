import { useEffect, useState } from "react"
import upcomingEvents from "../../../constants/upcomingEvents";
import EventsCard from "./EventsCard";

export default function EventsContainer() {

    const [eventsCollection, setEventsCollection] = useState([]);

    useEffect(() => {
        setEventsCollection(prevEventsCollection => {
            return upcomingEvents.map(upcomingEvent => {
                return <EventsCard prize={upcomingEvent.prize} date={upcomingEvent.date} time={upcomingEvent.time} venue={upcomingEvent.venue} title={upcomingEvent.title} game={upcomingEvent.game} url={upcomingEvent.url} />
            })
        })
    }, [])

    return (
        <div className="events-container">
            {eventsCollection}
        </div>
    )
}