import JoinUsSubmitButton from '../../Buttons/JoinUsSubmitButton';


export default function JoinUsForm({isButtonDisabled}) {

    return (
        <form className="join-us-form">
            <JoinUsSubmitButton text="Apply Now" isDisabled={isButtonDisabled} />
        </form>
    )
}